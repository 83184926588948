import React, { useEffect, useState } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLink,
  NavBtn,
  NavBtnLink,
  SwyftLogo,
  SwyftBgLogo,
} from "./NavBarElements";
import logoBg from "../../images/logoBg.svg";
import Swyft from '../../images/swyft.svg'

import { FaBars } from "react-icons/fa";
import { HiOutlineTruck } from "react-icons/hi";
import { LiaCarSideSolid } from "react-icons/lia";

import { animateScroll as scroll } from "react-scroll";

// Update these imports for Lucide icons
import { LuBriefcase, LuMail, LuWallet } from "react-icons/lu";
import { Handshake } from "lucide-react";

const NavBar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
      <Nav scrollNav={scrollNav}>
            <SwyftBgLogo src={logoBg} scrollNav={scrollNav} />
        <NavbarContainer>
          <NavLogo scrollNav={scrollNav} to="/" onClick={toggleHome}>
            <SwyftLogo src={Swyft}/>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink
                to="consumeraf"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                <LiaCarSideSolid size={18} style={{ strokeWidth: '0.7' }} />
                Consumer Asset
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="commercialaf"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                <HiOutlineTruck size={18} />
                Commercial Asset
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="businessloans"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                <LuBriefcase size={18} />
                Business Loans
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="personalLoans"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                <LuWallet size={18} />
                Personal Loans
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="partners"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                <Handshake size={18} />
                Partners
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                to="footer"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                activeClass="active"
              >
                <LuMail size={18} />
                Contact
              </NavLink>
            </NavItem>
          <NavBtn>
            <NavBtnLink to="/sign-in">Sign In</NavBtnLink>
          </NavBtn>
          </NavMenu>
        </NavbarContainer>
      </Nav>
  );
};

export default NavBar;
