import React, {useState} from 'react'
import Affiliations from '../Components/Affiliations';
import Footer from '../Components/footer';
import HeroSection from '../Components/HeroSection';
import { InfoSection } from '../Components/InfoSection';
import { homeObjOne, homeObjThree, homeObjTwo, homeObjFour } from '../Components/InfoSection/Data';
import Navbar from '../Components/navbar'
import Services from '../Components/services';
import SideBar from '../Components/Sidebar'


const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen)
  };
    
  return (
    <>
      <SideBar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      <InfoSection {...homeObjTwo} />
      <InfoSection {...homeObjThree} />
      <InfoSection {...homeObjFour} />

      <Services {...{ id: "partners" }}></Services>
      <Affiliations {...{ id: "recognition" }} />
      <Footer {...{ id: "footer" }} />
    </>
  );
};

export default Home