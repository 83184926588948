import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  background-color: ${({ scrollNav }) => (scrollNav ? `#4E8395` : "transparent")};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
`;

export const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;

  @media screen and (max-width: 1080px) {
    height: 60px;
  }
`;

export const NavLogo = styled(LinkR)`
  color: white;
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: start;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  padding-left: 5.5rem;
  padding-top: 3.5rem;

  @media screen and (max-width: 1400px) {
    padding-left: 4.5rem;
  }

  @media screen and (max-width: 1280px) {
    padding-left: 3.5rem;
    padding-top: 1.5rem;
  }

  @media screen and (max-width: 1080px) {
    padding-left: ${({ scrollNav }) => (scrollNav ? `2rem` : `2.5rem`)};
    padding-top: ${({ scrollNav }) => (scrollNav ? `0` : `1.2rem`)};
  }

  @media screen and (max-width: 480px) {
    padding-left: 1.8rem;
    padding-top: 0;
    font-size: 1.1rem;
  }
`;

export const SwyftLogo = styled.img`
  width: 120px;
  margin-right: 170px;

  @media screen and (max-width: 1400px) {
    width: 90px;
  }

  @media screen and (max-width: 1280px) {
    width: 80px;
    margin-right: 120px;
  }

  @media screen and (max-width: 1080px) {
    width: 70px;
    margin-right: 100px;
  }

  @media screen and (max-width: 480px) {
    width: 60px;
    margin-right: 60px;
  }
`;

export const SwyftBgLogo = styled.img`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 393px;

  @media screen and (max-width: 1400px) {
    width: 350px;
  }

  @media screen and (max-width: 1280px) {
    width: 280px;
  }

  @media screen and (max-width: 1080px) {
    width: 220px;
    display: ${({ scrollNav }) => (scrollNav ? "none" : "block")};
  }

  @media screen and (max-width: 480px) {
    width: 180px;
  }
`;

export const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 1100px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.5rem;
    cursor: pointer;
    color: white;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  height: 100%;
  padding-right: 40px;

  @media screen and (max-width: 1280px) {
    padding-right: 30px;
  }

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 100%;
`;

export const NavLink = styled(LinkS)`
  color: ${({ scrollNav }) => (scrollNav ? `#4E8395` : "#FFFFFF")};
  display: flex;
  min-width: max-content;
  font-size: min(calc(0.1rem + 0.8vw), 1.1rem);
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #f7954e;
    color: #f7954e;
  }

  &:hover {
    border-bottom: 2px solid #f7954e;
    color: #f7954e;
  }

  @media screen and (max-width: 1400px) {
    padding: 0 0.7rem;
    font-size: calc(0.1rem + 0.8vw);
  }

  @media screen and (max-width: 1280px) {
    padding: 0 0.4rem;
    font-size: 0.7rem;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  padding-left: 30px;

  @media screen and (max-width: 1400px) {
    padding-left: 25px;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #f7954e;
  white-space: nowrap;
  padding: 10px 40px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: white;
    color: #010606;
  }

  @media screen and (max-width: 1280px) {
    padding: 8px 30px;
    font-size: 15px;
  }
`;
