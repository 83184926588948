import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AffiliationsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #f9f9f9;
  max-width: 1340px;
  width: 90%;
  height: auto;
  min-height: 470px;
  margin: 0 auto;
  padding: 63px 20px 47px 20px;
  z-index: 1;
  flex-grow: 1;
  border-radius: 35px;
  box-shadow: 0px 10px 44px 0px rgba(0, 0, 0, 0.11);
  background-image: url(${require("../../images/affiliationsBg.png")});
  background-size: cover;
  background-position: center;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

export const AffiliationsContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AffiliationsHeader = styled.h1`
  color: #000000;
  text-align: center;
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 55px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 1.2;
  }

  @media (max-width: 480px) {
    font-size: 1.75rem;
  }
`;

export const AffiliationsSubHeader = styled.p`
  color: #666666;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 36px;
  max-width: 600px;

  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.5;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

export const AffiliationsLogos = styled.div`
  display: flex;
  justify-content: center;
  gap: 51px;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    gap: 30px;
    margin-top: 40px
  }

  @media (max-width: 480px) {
    gap: 20px;
  }
`;

export const AffiliationLogo = styled.img`
  max-width: 100%;
  height: auto;

  @media (max-width: 768px) {
    max-width: 80%;
  }

  @media (max-width: 480px) {
    max-width: 60%;
  }
`;
