import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesP,
  ContentWrapper,
  TextContent,
  PartnerTypesWrapper,
  PartnerType,
  IconWrapper,
  PartnerText,
  ImageContent,
} from "./ServicesElements";

// Import your SVG icons
import MortgageBrokerIcon from "../../images/mortgage_broker.png";
import AccountantIcon from "../../images/accountants.png";
import AssetDealerIcon from "../../images/asset_dealers.png";
import FinancialPlannerIcon from "../../images/financial_planners.png";
import SubcontractorNetworkIcon from "../../images/subcontractor_networks.png";
import AffiliateMarketerIcon from "../../images/affiliate_marketers.png";
import PartnersIllustration from "../../images/partners_illustration.svg";

const Services = ({ id }) => {
  let description =
    "Did you know 25 % of our referrers have earned over $22k in referral commissions in FY24.";

  const partnerTypes = [
    { type: "Mortgage Broker", icon: MortgageBrokerIcon },
    { type: "Accountants", icon: AccountantIcon },
    { type: "Asset Dealers", icon: AssetDealerIcon },
    { type: "Financial Planners", icon: FinancialPlannerIcon },
    { type: "Subcontractor Networks", icon: SubcontractorNetworkIcon },
    { type: "Affiliate Marketers", icon: AffiliateMarketerIcon },
  ];

  return (
    <ServicesContainer id={id}>
      <ContentWrapper>
        <TextContent>
          <ServicesH1>
            Join over <span style={{ color: "#E07C1C" }}>250 partners</span> who
            entrust us. Improve your{" "}
            <span style={{ color: "#4E8395" }}>client experience</span> using
            our platform.
          </ServicesH1>
          <PartnerTypesWrapper>
            {partnerTypes.map(({ type, icon }, index) => (
              <PartnerType key={index}>
                <IconWrapper>
                  <img src={icon} alt={type} />
                </IconWrapper>
                <PartnerText>{type}</PartnerText>
              </PartnerType>
            ))}
          </PartnerTypesWrapper>
        </TextContent>
        <ImageContent>
          <img src={PartnersIllustration} alt="Partners illustration" />
          <ServicesP>
            {description}
          </ServicesP>
        </ImageContent>
      </ContentWrapper>
    </ServicesContainer>
  );
};

export default Services;
