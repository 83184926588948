import styled from "styled-components";

export const InfoContainer = styled.div`
  position: relative;
  color: #fff;
  background-color: #fff;

  @media screen and (max-width: 768px) {
    padding: 50px 0;
  }
  padding: 80px 0;
  display: flex;
  align-items: center;
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  height: 100%;
  width: 100%;
  padding: 0 24px;
`;

export const InfoRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 60px;
  flex-direction: ${({ imgStart }) => (imgStart ? "row-reverse" : "row")};

  @media screen and (max-width: 1080px) {
    gap: 40px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Column1 = styled.div`
  padding: 0 80px;
  margin-top: 15px;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Column2 = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const TextWrapper = styled.div`
  max-width: 560px;
  padding-bottom: 10px;
  padding-left: 5px;
`;

export const Heading = styled.h1`
  margin-bottom: 60px;
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  color: #010606;

  @media screen and (max-width: 1080px) {
    margin-bottom: 45px;
    font-size: 32px;
    line-height: 42px;
  }

  @media screen and (max-width: 480px) {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 30px;
  }
`;

export const TopLine = styled.h2`
  display: inline-block;
  color: #4e8395;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 33px;

  @media screen and (max-width: 1080px) {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 480px) {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 20px;
  }
`;

export const TopLineHightlight = styled.h2`
  display: inline-block;
  color: #f7954e;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  margin-bottom: 33px;

  @media screen and (max-width: 1080px) {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 480px) {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 20px;
  }
`;

export const SubTitle = styled.p`
  font-family: "Poppins", sans-serif;
  color: #263238;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #010606;

  @media screen and (max-width: 1080px) {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 587px;
  height: 100%;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  border-radius: 20px;
`;

export const BlueShadow = styled.img`
  position: fixed;
  width: 80%;
  max-width: 796px;
  aspect-ratio: 1;
  top: 25%;
  right: 0;
  transform: translate(20%, -50%);
  z-index: -1;
`;

export const RedShadow = styled.img`
  position: fixed;
  width: 80%;
  max-width: 796px;
  aspect-ratio: 1;
  top: 75%;
  left: 0;
  transform: translate(-20%, -50%);
  z-index: -1;
`;

export const Buttons = styled.div`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? `#01bf71` : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? `#fff` : "#01bf71")};
  }
`;
