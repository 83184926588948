import React from "react";
import redShadow from "../../images/redShadow.svg";
import blueShadow from "../../images/blueShadow.svg";
import {
  InfoWrapper,
  InfoContainer,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  TopLineHightlight,
  Heading,
  SubTitle,
  ImgWrap,
  Img,
  BlueShadow,
  RedShadow,
} from "./InfoElements";

export const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  topLineHighlight,
  lightText,
  headline,
  darkText,
  description,
  img,
  alt,
  shadow,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                <TopLine lightBg={lightBg}>{topLine}&nbsp;</TopLine>
                <TopLineHightlight>{topLineHighlight}</TopLineHightlight>
                <SubTitle darkText={darkText}>{description}</SubTitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={img} type="video/mp4" alt={alt}></Img>
              </ImgWrap>
              {shadow === "blue" ? (
                <BlueShadow src={blueShadow} />
              ) : (
                shadow === "red" && <RedShadow src={redShadow} />
              )}
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
