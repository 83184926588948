import React from "react";
import {
  Container,
  AffiliationsSection,
  AffiliationsContent,
  AffiliationsHeader,
  AffiliationsSubHeader,
  AffiliationsLogos,
  AffiliationLogo,
} from "./AffiliationElements";

const Affiliations = ({ id }) => {
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Form sending logic here
  // };

  return (
    <Container>
      <AffiliationsSection id={id}>
        <AffiliationsContent>
          <AffiliationsHeader>
            Affiliations and{" "}
            <span style={{ color: "#E07C1C" }}>Awards.</span>
          </AffiliationsHeader>
          <AffiliationsSubHeader>
            Swyft Finance, is a proud member, recipient or <br /> advocate for
            the following industry bodies.
          </AffiliationsSubHeader>
        </AffiliationsContent>
        <AffiliationsLogos>
          <AffiliationLogo
            src={require("../../images/fbaa_transparent.png")}
            alt="FBAA"
            width="146"
            height="81"
          />
          <AffiliationLogo
            src={require("../../images/connective_transparent.png")}
            alt="Connective"
            width="282"
            height="69"
          />
          <AffiliationLogo
            src={require("../../images/transparent_award.png")}
            alt="Award"
            width="285"
            height="146"
          />
          <AffiliationLogo
            src={require("../../images/afca_transparent.png")}
            alt="AFCA"
            width="207"
            height="96"
          />
        </AffiliationsLogos>
      </AffiliationsSection>
    </Container>
  );
};

export default Affiliations;
