import styled from "styled-components";

export const ServicesContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 40px 20px;
  margin-top: 100px;
  margin-bottom: 55px;

  @media screen and (max-width: 768px) {
    padding: 30px 15px;
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;

export const ServicesH1 = styled.h1`
  font-size: 36px;
  font-weight: 700;
  line-height: 1.3;  
  margin-bottom: 40px;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export const ServicesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 500px;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const PartnerTypesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 600px;
  margin-top: 30px;

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

export const PartnerType = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: #4e8395;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  flex-shrink: 0;

  img {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 480px) {
    width: 32px;
    height: 32px;

    img {
      width: 20px;
      height: 20px;
    }
  }
`;

export const PartnerText = styled.span`
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }

  @media screen and (max-width: 480px) {
    font-size: 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 968px) {
    flex-direction: column;
  }
`;

export const TextContent = styled.div`
  flex: 1;
  padding-right: 40px;

  @media screen and (max-width: 968px) {
    padding-right: 0;
    margin-bottom: 40px;
  }
`;

export const ImageContent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  @media screen and (max-width: 968px) {
    margin: 0 auto;
  }
`;

export const ServicesP = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 80.2%;
  left: 48%;
  height: 15%;
  transform: translateX(-50%);
  font-weight: 500;
  line-height: 1.3;
  width: 35%;
  font-size: min(1.2cqw, 14px);

  @media screen and (max-width: 968px) {
    font-size: min(2.5cqw, 14px);
  }
`;