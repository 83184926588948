import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  position: relative;
  width: 100%;
  background-color: #255565;
  min-height: 569px;
  display: flex;
  margin-top: 236px;
  align-items: flex-end;
  z-index: 1;
  @media (max-width: 768px) {
    margin-top: 500px;
  }

  @media (max-width: 480px) {
    margin-top: 580px;
  }
`;

export const FooterWrap = styled.div`
  padding: 63px 105px;
  width: 100%;

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`;

export const FormSection = styled.div`
  position: absolute;
  bottom: 40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4e8395;
  padding: 0px 60px 35px 60px;
  color: #ffffff;
  border-radius: 35px;
  margin: 0 auto;
  width: 90%;
  max-width: 1269px;
  box-shadow: 0px 19px 55px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 19px 55px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 19px 55px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    padding: 0px 30px 25px 30px;
  }

  @media (max-width: 480px) {
    padding: 0px 20px 20px 20px;
  }
`;

export const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 70% 30%;
  gap: 20px;

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContent = styled.div`
  flex: 1;

  @media (max-width: 968px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const FormTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const FormDescription = styled.p`
  color: #ffffff;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`;

export const StyledForm = styled.form`
  display: grid;
  gap: 16px;
  max-width: 762px;
  padding-right: 24px;
  grid-template-columns: 42% 58%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-right: 0;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 24px;

  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

export const StyledLabel = styled.label`
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 6px;
`;

export const StyledInput = styled.input`
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 60px;
`;

export const StyledTextarea = styled.textarea`
  padding: 10px;
  border: none;
  border-radius: 4px;
  width: 100%;
  height: 60px;

  @media (max-width: 768px) {
    height: 120px;
  }
`;

export const SubmitButton = styled.button`
  background-color: #f7954e;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  margin-top: 26px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 20px;
  width: 100%;
  height: 60px;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const IllustrationContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (max-width: 968px) {
    display: none;
  }
`;

export const Illustration = styled.img`
  max-width: 369px;
  width: 100%;
  height: auto;
`;

export const SocialMedia = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const SocialClaim = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  max-width: 550px;
  color: #ffffff;

  @media (max-width: 768px) {
    font-size: 0.8rem;
    line-height: 1.4;
  }
`;

export const TabContainer = styled.div`
  display: flex;
  width: fit-content;
  margin-bottom: 25px;
  border: .8px solid lightgray;
  border-top: none;
  border-radius: 0 0 10px 10px;

  @media (max-width: 768px) {
    margin: 0 auto 25px 10px;
  }
`;

export const TabButton = styled.button`
  background-color: ${props => props.active ? '#f7954e' : 'transparent'};
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:first-child {
    border-radius: 0 0 0 10px;
  }

  &:last-child {
    border-radius: 0 0 10px 0;
  }

  &:hover {
    background-color: ${props => props.active ? '#f7954e' : 'rgba(255, 255, 255, 0.1)'};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 8px 12px;
  }

`;
