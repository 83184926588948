import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import financialGrowthIllustration from "../../images/financial_growth.svg";
import enquiryIllustration from "../../images/enquiry_form.svg";
import SwyftLogo from "../SwyftLogo";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialLogo,
  SocialClaim,
  FormSection,
  FormContainer,
  FormContent,
  FormTitle,
  FormDescription,
  StyledForm,
  InputWrapper,
  StyledLabel,
  StyledInput,
  StyledTextarea,
  SubmitButton,
  IllustrationContainer,
  Illustration,
  TabContainer,
  TabButton,
} from "./FooterElements";

const Footer = ({id}) => {
  const [activeTab, setActiveTab] = useState("partner");
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    const isEnquiry = activeTab === "enquiry";

    const templateParams = {
      form_type: isEnquiry ? "enquiry" : "partner",
      is_enquiry: isEnquiry,
      // Common fields
      name: form.current[isEnquiry ? "enquiry_name" : "partner_name"].value,
      email: form.current[isEnquiry ? "enquiry_email" : "partner_email"].value,
      phone: form.current[isEnquiry ? "enquiry_mobile" : "partner_phone"].value,
      // Enquiry-specific field
      description: isEnquiry ? form.current.enquiry_description.value : "",
      // Partner-specific field
      company_name: !isEnquiry ? form.current.partner_name.value : "",
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(
            `${isEnquiry ? "Enquiry" : "Partner"} email sent successfully:`,
            result.text
          );
          toast.success("Form submitted successfully!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          form.current.reset(); // Clear the form
        },
        (error) => {
          console.error(
            `Failed to send ${isEnquiry ? "enquiry" : "partner"} email:`,
            error.text
          );
          toast.error("Failed to submit form. Please try again.", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  };

  return (
    <FooterContainer id={id}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="toast-container"
      />
      <FormSection>
        <FormContainer>
          <FormContent>
            <TabContainer>
              <TabButton
                active={activeTab === "partner"}
                onClick={() => setActiveTab("partner")}
              >
                Become a Partner
              </TabButton>
              <TabButton
                active={activeTab === "enquiry"}
                onClick={() => setActiveTab("enquiry")}
              >
                Enquiry Form
              </TabButton>
            </TabContainer>
            <FormTitle>
              {activeTab === "partner"
                ? "Become a Swyft Partner™ today."
                : "Looking for finance? Enquire now."}
            </FormTitle>
            <FormDescription>
              {activeTab === "partner"
                ? "Working to improve your client's financial position is an endeavour that requires as many tools as possible. Finding the right leverage options can become increasingly difficult and having access to an opinion in smaller ticket financing may just be the answer."
                : "Have a question or need more information? Fill out the form below and we'll get back to you as soon as possible."}
            </FormDescription>
            <StyledForm ref={form} onSubmit={handleSubmit}>
              {activeTab === "partner" ? (
                <>
                  <InputWrapper>
                    <StyledLabel htmlFor="partnerName">
                      Company Name
                    </StyledLabel>
                    <StyledInput id="partnerName" name="partner_name" type="text" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="partnerEmail">
                      Business Email
                    </StyledLabel>
                    <StyledInput id="partnerEmail" name="partner_email" type="email" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="partnerPhone">
                      Phone Number
                    </StyledLabel>
                    <StyledInput id="partnerPhone" name="partner_phone" type="tel" required />
                  </InputWrapper>
                </>
              ) : (
                <>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryName">Name</StyledLabel>
                    <StyledInput id="enquiryName" name="enquiry_name" type="text" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryEmail">Email</StyledLabel>
                    <StyledInput id="enquiryEmail" name="enquiry_email" type="email" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryMobile">Mobile</StyledLabel>
                    <StyledInput id="enquiryMobile" name="enquiry_mobile" type="tel" required />
                  </InputWrapper>
                  <InputWrapper>
                    <StyledLabel htmlFor="enquiryDescription">Description</StyledLabel>
                    <StyledTextarea
                      id="enquiryDescription"
                      name="enquiry_description"
                      placeholder="Write your description..."
                      required
                    />
                  </InputWrapper>
                </>
              )}
              <InputWrapper>
                <SubmitButton type="submit">
                  {activeTab === "partner" ? "Contact Us" : "Send Enquiry"}
                </SubmitButton>
              </InputWrapper>
            </StyledForm>
          </FormContent>
          <IllustrationContainer>
            <Illustration
              src={activeTab === "partner" ? financialGrowthIllustration : enquiryIllustration}
              alt={
                activeTab === "partner"
                  ? "Financial growth illustration"
                  : "Enquiry illustration"
              }
            />
          </IllustrationContainer>
        </FormContainer>
      </FormSection>
      <FooterWrap>
        <SocialMedia>
          <SocialLogo to="/">
            <SwyftLogo H={48.8} W={100} />
          </SocialLogo>
          <SocialClaim>
            SPG Capital Pty Ltd trading as Swyft Finance Partners ABN 80 652 504
            599 are Credit Representatives (CRN: 537077) under Connective Credit
            Services Australian Credit License (ACL: 389328).
          </SocialClaim>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
