import React from 'react'
import { 
  SideBarContainer, 
  Icon, 
  CloseIcon, 
  SideBarWrapper, 
  SidebarMenu,
  SideBtnWrap,
  SidebarRoute,
  SidebarLink  
} from './SidebarElements'

// Import icons
import { HiOutlineTruck } from "react-icons/hi";
import { LiaCarSideSolid } from "react-icons/lia";
import { LuBriefcase, LuMail, LuWallet } from "react-icons/lu";
import { Handshake } from "lucide-react";

const SideBar = ({ isOpen, toggle }) => {
  return (
    <SideBarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SideBarWrapper>
        <SidebarMenu>
          <SidebarLink to='consumeraf' onClick={toggle}>
            <LiaCarSideSolid size={20} style={{ strokeWidth: '0.7' }} />
            Consumer Asset
          </SidebarLink>
          <SidebarLink to='commercialaf' onClick={toggle}>
            <HiOutlineTruck size={20} style={{ strokeWidth: '1.5' }} />
            Commercial Asset
          </SidebarLink>
          <SidebarLink to='businessloans' onClick={toggle}>
            <LuBriefcase size={20} />
            Business Loans
          </SidebarLink>
          <SidebarLink to='personalLoans' onClick={toggle}>
            <LuWallet size={20} />
            Personal Loans
          </SidebarLink>
          <SidebarLink to='partners' onClick={toggle}>
            <Handshake size={20} />
            Partners
          </SidebarLink>
          <SidebarLink to='footer' onClick={toggle}>
            <LuMail size={20} />
            Contact
          </SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute to="/sign-in">
            Sign In
          </SidebarRoute>
        </SideBtnWrap>
      </SideBarWrapper>
    </SideBarContainer>
  )
}

export default SideBar