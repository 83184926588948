import styled from 'styled-components'

import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'

import { FaTimes } from 'react-icons/fa'

export const SideBarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #1A1A1A; // Darker background for better contrast
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
    top: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
    color: #fff;
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

`

export const SideBarWrapper = styled.div`
    color: #fff;
    padding: 0 24px; // Add some padding
`

export const SidebarMenu = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    padding: 0; // Remove default padding

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 50px); // Further reduce height on small screens
    }
`

export const SidebarLink = styled(LinkS)`
    display: flex;
    align-items: center;
    justify-content: flex-start; // Align items to the left
    font-size: min(calc(0.5rem + 4vw), 1.5rem); // Slightly smaller font size: ;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;
    padding: 0 0 6px 10px; // Add left padding
    border-bottom: 1px solid #4E8395;

    svg {
        margin-right: 10px; // Add space between icon and text
    }

    &:hover {
        color: #4E8395; // Match button color on hover
        transition: 0.2s ease-in-out;
    }
`

export const SideBtnWrap = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px; // Increase top margin
`

export const SidebarRoute = styled(LinkR)`
    border-radius: 50px;
    background: #4E8395;
    white-space: nowrap;
    text-align: center;
    padding: 12px 32px; // Reduce padding
    width: 100%;
    color: #fff;
    font-size: 16px; // Slightly smaller font size
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #E6AB99;
        color: #4E8395;
    }
`

